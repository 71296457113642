/* Font Faces */
@font-face {
    font-family: 'Slipstream';
    src: url('../assets/SLIPSTRM.TTF') format('truetype');
}

@font-face {
    font-family: 'ALD721L';
    src: url('../assets/ALD721L.TTF') format('truetype');
}

@font-face {
    font-family: 'Arial';
    src: url('../assets/ArialMdm.ttf') format('truetype');
}

/* Root Variables */
:root {
    --header-height: 25px;
    --footer-height: 60px;
}

/* Global Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'ALD721L', Arial, sans-serif;
    overflow-x: hidden;
    font-size: 16px;
}

/* Prevent zooming */
html {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevent scrolling on webkit browsers */
body {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#app {
    background: url('../assets/background7.png') no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

/* Top Bar Styles */
#topBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    background: linear-gradient(to bottom, 
        #222222 45%,
        #1a1a1a 60%,
        #0d0d0d 80%,
        #121212 100%);
}

#tickerContainer {
    width: 100%;
    overflow: hidden;
    padding: 2px 0;
    border: none;
    margin-bottom: -21px;
    height: 19px;
    line-height: 15px;
    background-image: url('../assets/topheader.9.png');
    background-size: cover;
    position: relative;
}

#tickerContainer::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.15) 50%), 
                linear-gradient(90deg, rgba(255, 0, 0, 0.02), rgba(0, 255, 0, 0.01), rgba(0, 0, 255, 0.02));
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
    z-index: 2;
}

#tickerContainer::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.05);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: flicker 0.25s infinite;
}

.ticker-content {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;
    animation: ticker 60s linear infinite;
    color: white;
    font-size: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    margin-top: 2px;
}

@keyframes ticker {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-300%, 0, 0); }
}

/* Logo Styles */
.center-container {
    position: absolute;
    top: calc(var(--header-height) + 50px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

#logo {
    width: 30vw;
    height: 30vw;
    max-width: 232px;
    max-height: 232px;
    min-width: 116px;
    min-height: 116px;
    background: url('../assets/logo.png') no-repeat center center;
    background-size: contain;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: translateY(0) !important; filter: brightness(1) !important; transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1), filter 0.3s cubic-bezier(0.33, 1, 0.68, 1), box-shadow 0.6s cubic-bezier(0.33, 1, 0.68, 1) !important; 
    will-change: transform, filter, box-shadow !important;
    cursor: pointer;
}

#logo:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.05) !important;
    box-shadow:
    0 8px 16px rgba(0, 0, 0, 0.15),
    0 4px 8px rgba(0, 0, 0, 0.12) !important;
}

#logo:active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1) !important;
    transform: translateY(2px) !important;
    transform: translateX(2px) !important;
    filter: brightness(0.98) !important;
    box-shadow:
    0 8px 16px rgba(0, 0, 0, 0),
    0 4px 8px rgba(0, 0, 0, 0) !important;
}

/* Button Styles */
#exitButton, #walletButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10px !important;
    margin-bottom: 9px !important;
    margin-right: 0px !important;
    padding: 0px 11px !important;
    margin-top: 7px !important;
    font-family: Arial, sans-serif;
    font-size: 12px;
    font-weight: normal !important; 
    letter-spacing: 0.05em;
    color: white;
    background: none;
    border: none;
    padding: 8px;
    text-transform: lowercase;
}

#exitButton:hover,
#walletButton:hover {
    transform: scale(1.03) !important;
    transition: transform 0.8s ease !important;
}

.menuButton {
    width: 160px;
    height: 44px;
}

#buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: var(--header-height);
    background-color: transparent;
}

.button-text {
    transform: translateX(-3px);
    pointer-events: none;
}

.wallet-adapter-button-trigger {
    display: flex !important;
    align-items: center !important;
    height: 10px !important;
    margin-bottom: 9px !important;
    margin-right: 0px !important;
    padding: 15px 9px !important;
    margin-top: 7px !important;
    font-size: 12px !important;
    cursor: pointer !important;
    transition: all 0.8s ease !important;
    gap: 2px !important;
    border: none !important;
    background: transparent !important;
    color: #FFFFFF !important;
    font-family: arial !important;
    font-weight: normal !important;
    text-transform: lowercase !important;
}

.wallet-adapter-button-trigger::before {
    content: '' !important;
    display: inline-block !important;
    width: 14px !important;
    height: 14px !important;
    margin-bottom: -.8px !important;
    background: url('../assets/money.png') center/contain no-repeat !important;
    margin-right: 1px !important;
    transition: background-image 0.8s ease !important;
}

.wallet-adapter-button-trigger:hover {
    transform: scale(1.03) !important;
}

.wallet-adapter-button-trigger:hover::before {
    background: url('../assets/money.webp') center/contain no-repeat !important;
}

/* Wallet Modal/Popup Styles */
.wallet-adapter-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    transition: opacity 150ms linear;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-adapter-modal-wrapper {
    background: #2C2D30;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.6);
    min-width: 360px;
    max-width: 400px;
    padding: 32px;
    position: relative;
    text-align: center;
}

.wallet-adapter-modal-title {
    font-size: 24px !important;
    font-weight: 500;
    color: #FFFFFF;
    margin: 0 0 16px;
}

.wallet-adapter-modal-list {
    list-style: none;
    margin: 0 0 12px;
    padding: 0;
}

.wallet-adapter-modal-list .wallet-adapter-button {
    display: flex;
    align-items: center;
    font-family: 'Arial', sans-serif !important;
    font-size: 16px !important;
    font-weight: normal;
    padding: 12px 20px;
    margin: 0px 0;
    width: 100%;
    background-color: #00000000;
    border-radius: 6px;
    color: #FFFFFF;
    cursor: pointer;
    transition: background-color 150ms linear;
}

.wallet-adapter-modal-list .wallet-adapter-button:hover {
    background-color: #4e4f5200;
}

/* Close button for modal */
.wallet-adapter-modal-button-close {
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #777;
}

/* Connected wallet state */
.wallet-adapter-button-connected {
    font-size: 12px !important;
    font-family: arial !important;
}

.wallet-adapter-button-connected-address {
    font-size: 12px !important;
    font-family: arial !important;
    color: #FFFFFF !important;
}

/* Wallet icon in the connect button */
.wallet-adapter-button-trigger .wallet-adapter-button-start-icon {
    display: none !important;
    margin-right: 5px !important;
    width: 18px !important;
    height: 18px !important;
}

/* Wallet icon in the modal */
.wallet-adapter-modal-list .wallet-adapter-button .wallet-adapter-button-start-icon {
    display: inline-flex !important;
    margin-right: 20px !important;
    width: 12px !important;
    height: 12px !important;
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {
    .wallet-adapter-modal-wrapper {
        min-width: 320px;
        padding: 24px;
    }
    
    .wallet-adapter-modal-title {
        font-size: 20px !important;
    }
    
    .wallet-adapter-modal-list .wallet-adapter-button {
        font-size: 14px !important;
        padding: 10px 16px;
    }
}

/* Card Styles */
.card {
    background-image: url('../assets/memecard.9.png');
    background-size: 100% 100%;
    width: 100%;
    max-width: 700px;
    height: auto;
    min-height: 632px;
    display: flex;
    flex-direction: column;
    padding: 20px 15px 10px;
    text-align: left;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    margin-top: 0px;
    margin-bottom: 0px;
}

.card-header {
    position: absolute;
    top: 13px;
    left: 16px;
    font-family: 'Slipstream', Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 0.05em;
    color: black;
    margin: 0;
    padding: 0;
    z-index: 2;
    width: calc(100% - 26px);
    overflow: visible;
    white-space: nowrap;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px;
    height: calc(100% - 32px);
    overflow-y: auto;
    align-items: center;
    position: relative;
    z-index: 0;
}

/* Card Specific Styles */
.card[data-index="0"] {
    background-image: url('../assets/stakingcard.9.png');
}

.card[data-index="1"] {
    background-image: url('../assets/ticket_card.9.png');
}

.card[data-index="2"] {
    background-image: url('../assets/memecard.9.png');
}

/* Terminal Styles */
.terminal-container {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 25px;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
}

.terminal-content {
    background: #161616;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    margin: 9px 0;
    color: #00ff00;
    font-family: 'Courier New', monospace;
    font-size: 1.0rem;
    line-height: 1;
    text-align: left;
    height: 430px;
    width: 670px;
    white-space: pre-wrap;
    box-sizing: border-box;
    position: relative;
    overflow: hidden !important;
    box-shadow:
    inset -2px -2px 0 0 #ffffff,
    inset 2px 2px 0 0 #7070707e,
    inset -4px -4px 0 0 #dfdfdf30,
    inset 4px 4px 0 0 #7070700e !important;
}

.terminal-content::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.15) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.02), rgba(0, 255, 0, 0.01), rgba(0, 0, 255, 0.02));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
}

.terminal-content::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.05);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
    animation: flicker 0.25s infinite;
}

@keyframes flicker {
    0% {opacity: 0.25;}
    5% {opacity: 0.28;}
    10% {opacity: 0.24;}
    15% {opacity: 0.30;}
    20% {opacity: 0.22;}
    25% {opacity: 0.29;}
    30% {opacity: 0.26;}
    35% {opacity: 0.27;}
    40% {opacity: 0.23;}
    45% {opacity: 0.28;}
    50% {opacity: 0.30;}
    55% {opacity: 0.22;}
    60% {opacity: 0.24;}
    65% {opacity: 0.27;}
    70% {opacity: 0.25;}
    75% {opacity: 0.23;}
    80% {opacity: 0.28;}
    85% {opacity: 0.26;}
    90% {opacity: 0.27;}
    95% {opacity: 0.24;}
    100% {opacity: 0.25;}
}

@keyframes textShadow {
    0% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    5% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    10% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    15% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    20% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    25% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    30% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    35% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    40% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    45% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    50% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    55% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    60% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    65% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    70% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    75% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    80% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    85% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    90% {text-shadow: 0.3px 0 1px rgba(0,30,255,0.3), -0.3px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    95% {text-shadow: 0.25px 0 1px rgba(0,30,255,0.3), -0.25px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
    100% {text-shadow: 0.2px 0 1px rgba(0,30,255,0.3), -0.2px 0 1px rgba(255,0,80,0.2), 0 0 2px;}
} 

/* Scrollbar Styles */
.terminal-content::-webkit-scrollbar {
    width: 8px;
}

.terminal-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.terminal-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
}

.terminal-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
}

/* Input Styles */
.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#stakeAmount, #purchaseAmount {
    background: #FFFFFF;
    color: rgb(38, 38, 38);
    font-size: 1rem;
    width: calc(100% - 90px);
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-top: 10px;
    margin-right: 0px;
    box-shadow:
    inset -1px -1px 0 0 #d8d8d8,
    inset 0.8px 0.8px 0 0 #00000050,
    inset -2.5px -2.5px 0 0 #efefefc5,
    inset 2.5px 2.5px 0 0 #807e7ed8 !important;
}

.card[data-index="1"] #purchaseAmount {
    width: 100%;
}

/* Optional: Add a subtle focus state */
#stakeAmount:focus, #purchaseAmount:focus {
    outline: none;
    box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1),
                0 0 0 2px rgba(66, 116, 138, 0.2);
}

.card[data-index="1"] #purchaseAmount {
    width: 100%;
}

#maxStakeButton {
    background-size: 100% 100%;
    padding: 0px 10px;
    color: grey;
    cursor: pointer;
    font-size: 0.875rem;
    width: 100px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 10px;
    margin-left: 40px;
}

/* Button Container Styles */
.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
    margin-top: auto;
}

/* Overlay Button Styles */
.overlay-button, #stakeButton, #unstakeButton, #purchaseButton, #redeemButton {
    background-size: 100% 100%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1rem;
    margin: 5px 5px;
    width: 190px;
    height: 44px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

/* MEME Card Specific Styles */
.card[data-index="2"] .card-content {
    display: flex;
    flex-direction: column;
}

.meme-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 10px;
    height: 100%;
}

.meme-content .top-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 0px;
}

.button-container.meme-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 208px;
}

.custom-button.choose-file {
    background-size: 100% 100%;
    font-family: 'ALD721L', Arial, sans-serif;
    color: blue;
    margin-top: 45px;
    Margin-bottom: 0px;
}

#imageCanvas {
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
}

.meme-content input[type="file"] {
    display: none;
}

/* Footer Styles */
#footer {
    background-color: transparent;
    padding: 0px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    min-height: var(--footer-height);
    z-index: 1000;
}

#footer .product {
    width: 100%;
    max-width: 468px;
    margin-bottom: 15px;
    transition: transform 0.3s ease, filter 0.3s ease;
}

#footer .product:hover {
    transform: translateY(-2px);
    filter: brightness(1.2);
}

#footer .product:active {
    transform: translateY(1px);
    filter: brightness(0.9);
}

#footer .product img {
    width: 100%;
    height: 31px;
    object-fit: contain;
    display: block;
}

#footer .social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
}

#footer .social a {
    display: inline-block;
    width: 88px;
    height: 30px;
    transition: transform 0.3s ease, filter 0.3s ease;
}

#footer .social a:hover {
    transform: translateY(-2px);
    filter: brightness(1.2);
}

#footer .social a:active {
    transform: translateY(1px);
    filter: brightness(0.9);
}

#footer .social a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Menu Styles */
#menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: fixed;
    width: auto;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(0px);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    pointer-events: none;
}

.popup-overlay.active {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    opacity: 1;
    pointer-events: auto;
}

.popup-content {
    background-color: #42748a8b;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(12px);
    border-radius: 7px;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Arial', Arial, sans-serif;
    border: 1.5px solid #42748a8a;
    transform: translateY(-20px) scale(0.95);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.popup-overlay.active .popup-content {
    transform: translateY(0) scale(1);
    opacity: 1;
}

.popup-content-inner {
    padding: 0 0 20px 0;
    font-size: 16px;
    font-family: 'Arial', Arial, sans-serif;
    color: #ffffff;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: 0.1s;
    text-transform: lowercase;
}

.popup-overlay.active .popup-content-inner {
    opacity: 1;
    transform: translateY(0);
}

.popup-button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    opacity: 0;
    transform: translateY(10px);
    transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: 0.2s;
}

.popup-overlay.active .popup-button-container {
    opacity: 1;
    transform: translateY(0);
}

.popup-button {
    background-color: #42748a;
    border: none;
    color: rgb(0, 0, 0);
    padding: 6px 22px 6px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0px 2px;
    cursor: pointer;
    font-family: 'Slipstream', Arial, sans-serif;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow:
        inset -1px -1px 0 0 #284755,
        inset 1px 1px 0 0 #abbdc6,
        inset -2px -2px 0 0 #315667,
        inset 2px 2px 0 0 #99b0baad;
}

.popup-button:hover {
    transform: translateY(-1px);
    filter: brightness(1.1);
}

.popup-button:active {
    transform: translateY(1px);
    filter: brightness(0.9);
}

/* Animation for popup exit */
.popup-overlay.closing {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
}

.popup-overlay.closing .popup-content {
    transform: translateY(20px) scale(0.95);
    opacity: 0;
}

.popup-overlay.closing .popup-content-inner,
.popup-overlay.closing .popup-button-container {
    opacity: 0;
    transform: translateY(10px);
}

/* Scrollbar styles */
.popup-content::-webkit-scrollbar {
    width: 8px;
}

.popup-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.popup-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.popup-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* BROKE NIGGA LAPTOP */
@media screen and (max-height: 820px), screen and (max-width: 1382px) {
    #logo {
        width: 18vw;
        height: 18vw;
}

#exitButton, #walletButton {
    padding: 0px 2px !important;
}

/* Wallet Adapter Styles with Animation */
.wallet-adapter-button {
    padding: 15px 1px !important;
}

/* Global Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'ALD721L', Arial, sans-serif;
    overflow-x: hidden;
    font-size: 16px;
}

/* Prevent zooming */
html {
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
}

/* Prevent scrolling on webkit browsers */

.ticker-content {
    display: inline-block;
    white-space: nowrap;
    padding-left: 100%;
    animation: ticker 60s linear infinite;
    color: white;
    font-size: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
}

@keyframes ticker {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-200%, 0, 0); }
}

/* Logo Styles */
.center-container {
    position: absolute;
    top: calc(var(--header-height) + 50px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

#logo {
    width: 30vw;
    height: 30vw;
    max-width: 232px;
    max-height: 232px;
    min-width: 116px;
    min-height: 116px;
    background: url('../assets/logo.png') no-repeat center center;
    background-size: contain;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.menuButton {
    width: 160px;
    height: 44px;
}

#buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    height: var(--header-height);
    background-color: transparent;
}

.button-text {
    transform: translateX(-3px);
    pointer-events: none;
}

/* Card Styles */
.card {
    background-image: url('../assets/memecard.9.png');
    background-size: 100% 100%;
    width: 100%;
    max-width: 568px;
    height: auto;
    min-height: 505.6px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px 10px;
    text-align: left;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    margin-top: 0px;
    margin-bottom: 0px;
}

.card-header {
    position: absolute;
    top: 10px;
    left: 16px;
    font-family: 'Slipstream', Arial, sans-serif;
    font-size: 1.3rem;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 0.05em;
    color: black;
    margin: 0;
    padding: 0;
    z-index: 2;
    width: calc(100% - 26px);
    overflow: visible;
    white-space: nowrap;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px;
    height: calc(100% - 32px);
    overflow-y: auto;
    align-items: center;
    position: relative;
    z-index: 0;
}

/* Card Specific Styles */
.card[data-index="0"] {
    background-image: url('../assets/stakingcard.9.png');
}

.card[data-index="1"] {
    background-image: url('../assets/ticket_card.9.png');
}

.card[data-index="2"] {
    background-image: url('../assets/memecard.9.png');
}

/* Terminal Styles */
.terminal-container {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 11px;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
}

.terminal-content {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 7px;
    margin: 9px 0;
    color: #00ff00;
    font-family: 'Courier New', monospace;
    font-size: .9rem;
    line-height: 1;
    text-align: left;
    height: 330px;
    width: 548px;
    overflow-y: auto;
    white-space: pre-wrap;
    box-sizing: border-box;
}

/* Scrollbar Styles */
.terminal-content::-webkit-scrollbar {
    width: 8px;
}

.terminal-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.terminal-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
}

.terminal-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
}

/* Input Styles */
.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#stakeAmount, #purchaseAmount {
    background-size: 100% 100%;
    padding: 0px 40px;
    color: black;
    font-size: 1.0rem;
    width: calc(100% - 90px);
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-top: 0px;
    margin-right: 0px;
}

.card[data-index="1"] #purchaseAmount {
    width: 100%;
}

#maxStakeButton {
    background-size: 100% 100%;
    padding: 0px 10px;
    color: grey;
    cursor: pointer;
    font-size: 0.875rem;
    width: 100px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 0px;
    margin-left: 40px;
}

/* Button Container Styles */
.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
    margin-top: auto;
}

/* Overlay Button Styles */
.overlay-button, #stakeButton, #unstakeButton, #purchaseButton, #redeemButton {
    background-size: 100% 100%;
    padding: 5px 10px;
    color: grey;
    cursor: pointer;
    font-size: 1.0rem;
    margin: 5px 5px;
    width: 190px;
    height: 44px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

/* MEME Card Specific Styles */
.card[data-index="2"] .card-content {
    display: flex;
    flex-direction: column;
}

.meme-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 10px;
    height: 100%;
}

.meme-content .top-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 0px;
}

.button-container.meme-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 130px;
}

.custom-button.choose-file {
    background-size: 100% 100%;
    font-family: 'ALD721L', Arial, sans-serif;
    color: blue;
    margin-top: 25px;
    Margin-bottom: 0px;
}

#imageCanvas {
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
}

.meme-content input[type="file"] {
    display: none;
}

/* Footer Styles */
#footer {
    background-color: transparent;
    padding: 0px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    min-height: var(--footer-height);
    z-index: 1000;
}

#footer .product {
    background: url('../assets/product.png') no-repeat center center;
    background-size: contain;
    width: 100%;
    max-width: 234px;
    height: 23.25px;
    margin-bottom: 6px;
}

#footer .product img {
    width: 100%;
    height: 23.25px;
    object-fit: contain;
    display: block;
}

#footer .social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    margin-bottom: 2px;
}

#footer .social a {
    display: inline-block;
    width: 66px;
    height: 22.5px;
}

#footer .social a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Menu Styles */
#menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: fixed;
    width: auto;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
}

/* Safe Area Insets */
@supports (padding-top: env(safe-area-inset-top)) {
    #topBar {
        padding-top: env(safe-area-inset-top);
    }

    .card {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 100px);
    }
}

/* MOBILE */
@media screen and (max-height: 620px), screen and (max-width: 768px) {
    #logo {
        width: 18vw;
        height: 18vw;
}

.ticker-content {
    font-size: 0.75rem;
    padding-left: 50%;
}

#tickerContainer {
    height: 16px;
    line-height: 12px;
    margin-bottom: -17px;
}

#exitButton, #walletButton {
    padding: 0px 2px !important;
}

/* Wallet Adapter Styles with Animation */
.wallet-adapter-button {
    padding: 15px 1px !important;
}

@keyframes ticker {
    0% { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(-500%, 0, 0); }
}

/* Logo Styles */
.center-container {
    position: absolute;
    top: calc(var(--header-height) - -50px);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

#logo {
    width: 30vw;
    height: 30vw;
    max-width: 232px;
    max-height: 232px;
    min-width: 150px;
    min-height: 150px;
    background: url('../assets/logo.png') no-repeat center center;
    background-size: contain;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.menuButton {
    width: 145px;
    height: 40px;
    margin-bottom: 0px;
}

#buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    height: var(--header-height);
    background-color: transparent;
}

.button-text {
    transform: translateX(-3px);
    pointer-events: none;
}

/* Card Styles */
.card {
    background-image: url('../assets/memecard.9.png');
    background-size: 100% 100%;
    width: 100%;
    max-width: 370px;
    height: auto;
    min-height: 385px;
    display: flex;
    flex-direction: column;
    padding: 25px 12.5px 10px;
    text-align: left;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    margin-top: 0px;
    margin-bottom: 0px;
}

.card-header {
    position: absolute;
    top: 7px;
    left: 13px;
    font-family: 'Slipstream', Arial, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
    letter-spacing: 0.05em;
    color: black;
    margin: 0px;
    padding: 0px;
    z-index: 2;
    width: calc(100% - 26px);
    overflow: visible;
    white-space: nowrap;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: 0px .55px 0;
    height: calc(100% - 32px);
    overflow-y: auto;
    align-items: center;
    position: relative;
    z-index: 0;
}

/* Card Specific Styles */
.card[data-index="0"] {
    background-image: url('../assets/stakingcard2.9.png');
}

.card[data-index="1"] {
    background-image: url('../assets/ticket_card2.9.png');
}

.card[data-index="2"] {
    background-image: url('../assets/memecard2.9.png');
}

/* Terminal Styles */
.terminal-container {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    width: 100%;
    max-width: 100%;
    flex-grow: 1;
}

.terminal-content {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 5px;
    margin: 9px 0;
    color: #00ff00;
    font-family: 'Courier New', monospace;
    font-size: .7rem;
    line-height: 1;
    text-align: left;
    height: 215px;
    width: 100%;
    overflow-y: auto;
    white-space: pre-wrap;
    box-sizing: border-box;
    box-shadow:
    inset -1px -2px 0 0 #ffffff,
    inset 1px 1px 0 0 #7070707e,
    inset -2px -2px 0 0 #dfdfdf30,
    inset 2px 2px 0 0 #7070700e !important;
}

.terminal-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0.15) 1px,
        transparent 1px,
        transparent 2px
    );
    pointer-events: none;
}

.terminal-content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
        circle at center,
        transparent 0%,
        rgba(0, 0, 0, 0.2) 90%
    );
    pointer-events: none;
}

@keyframes textShadow {
    0% {
        text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), 
                     -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 
                     0 0 3px;
    }
    5% {
        text-shadow: 2.7928974010788217px 0 1px rgba(0,30,255,0.5), 
                     -2.7928974010788217px 0 1px rgba(255,0,80,0.3), 
                     0 0 3px;
    }
    /* ... continues with text shadow animation */
    100% {
        text-shadow: 0.4389924193300864px 0 1px rgba(0,30,255,0.5), 
                     -0.4389924193300864px 0 1px rgba(255,0,80,0.3), 
                     0 0 3px;
    }
}

/* Scrollbar Styles */
.terminal-content::-webkit-scrollbar {
    width: 0px;
}

.terminal-content::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.terminal-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
}

.terminal-content::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
}

/* Input Styles */
.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

#stakeAmount, #purchaseAmount {
    background-size: 100% 100%;
    padding: 0px 40px;
    color: black;
    font-size: 1rem;
    width: calc(100% - 150px);
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-top: 10px;
    margin-right: 0px;
    box-shadow:
    inset -0.7px -0.7px 0 0 #d8d8d8,
    inset 0.6px 0.6px 0 0 #00000050,
    inset -1.5px -1.5px 0 0 #efefefc5,
    inset 1.5px 1.5px 0 0 #807e7ed8 !important;
}

.card[data-index="1"] #purchaseAmount {
    width: 100%;
}

/* Button Container Styles */
.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0px;
    margin-top: auto;
}

/* Overlay Button Styles */
.overlay-button, #stakeButton, #unstakeButton, #purchaseButton, #redeemButton {
    background-size: 100% 100%;
    padding: 5px 10px;
    color: grey;
    cursor: pointer;
    font-size: 1rem;
    margin: 0px 3px;
    width: 160px;
    height: 40px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

/* MEME Card Specific Styles */
.card[data-index="2"] .card-content {
    display: flex;
    flex-direction: column;
}

.meme-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 10px;
    height: 100%;
}

.meme-content .top-content {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
}

.button-container.meme-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 65px;
}

.custom-button.choose-file {
    margin-top: 20px;
    background-size: 100% 100%;
    font-family: 'ALD721L', Arial, sans-serif;
    color: blue;
    margin-top:10px;
    margin-bottom:10px;
}

#imageCanvas {
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
}

.meme-content input[type="file"] {
    display: none;
}

/* Footer Styles */
#footer {
    background-color: rgba(255, 0, 0, 0);
    padding: 0px 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    min-height: var(--footer-height);
    z-index: 1000;
}

#footer .product {
    background: url('../assets/product.png') no-repeat center center;
    background-size: contain;
    width: 100%;
    max-width: 234px;
    height: 23.25px;
    margin-bottom: 6px;
}

#footer .product img {
    width: 100%;
    height: 23.25px;
    object-fit: contain;
    display: block;
}

#footer .social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
    margin-bottom: 2px;
}

#footer .social a {
    display: inline-block;
    width: 66px;
    height: 22.5px;
}

#footer .social a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Menu Styles */
#menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: fixed;
    width: auto;
    left: 50%;
    top: 65%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
}

/* Update existing button styles */

@keyframes subtle-float {
    0% { transform: translateY(0); filter: brightness(1); }
    100% { transform: translateY(-2px); filter: brightness(1.02); }
}

.overlay-button, 
#stakeButton, 
#unstakeButton, 
#purchaseButton, 
#redeemButton {
    position: relative !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 190px !important;
    height: 44px !important;
    margin: 5px !important;
    background: #c0c0c0 !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow:
        inset -2px -2px 0 0 #000000,
        inset 2px 2px 0 0 #ffffff,
        inset -4px -4px 0 0 #808080,
        inset 4px 4px 0 0 #dfdfdf30 !important;
    color: #616161 !important;
    font-size: 1.0rem;
    padding: 6px 8px !important;
    transform: translateY(0) !important;
    filter: brightness(1) !important;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1),
                filter 0.5s cubic-bezier(0.33, 1, 0.68, 1) !important;
    will-change: transform, filter !important;
}

.overlay-button:active, 
#stakeButton:active, 
#unstakeButton:active, 
#purchaseButton:active, 
#redeemButton:active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1) !important;
    filter: brightness(0.98) !important;
    font-size: 0.96rem;
    background: #acacac !important;
    box-shadow:
        inset -2px -2px 0 0 #ffffff,
        inset 2px 2px 0 0 #000000,
        inset -4px -4px 0 0 #dfdfdf30,
        inset 4px 4px 0 0 #707070d8 !important;
    padding: 8px 6px 4px 10px !important;
}

/* Max Stake Button Windows 95 Style */
#maxStakeButton {
    position: relative !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100px !important;
    height: 44px !important;
    background: #c0c0c0 !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow:
        inset -2px -2px 0 0 #000000,
        inset 2px 2px 0 0 #ffffff,
        inset -4px -4px 0 0 #808080,
        inset 4px 4px 0 0 #dfdfdf30 !important;
    margin-top: 10px !important;
    margin-left: 40px !important;
    color: #616161 !important;
    font-size: 1.0rem;
    padding: 6px 8px !important;
    transform: translateY(0) !important;
    filter: brightness(1) !important;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1),
                filter 0.5s cubic-bezier(0.33, 1, 0.68, 1) !important;
    will-change: transform, filter !important;
}

#maxStakeButton:active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1) !important;
    filter: brightness(0.98) !important;
    font-size: 0.96rem;
    background: #acacac !important;
    box-shadow:
        inset -2px -2px 0 0 #ffffff,
        inset 2px 2px 0 0 #000000,
        inset -4px -4px 0 0 #dfdfdf30,
        inset 4px 4px 0 0 #707070d8 !important;
    padding: 8px 6px 4px 10px !important;
}

.menuButton {
    position: relative !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 160px !important;
    height: 44px !important;
    background: #42748a !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow:
        inset -2px -2px 0 0 #284755,
        inset 2px 2px 0 0 #abbdc6,
        inset -4px -4px 0 0 #315667,
        inset 4px 4px 0 0 #99b0baad !important;
    color: #000000 !important;
    font-family: 'Slipstream', Arial, sans-serif;
    font-size: 1rem;
    letter-spacing: 0.05em;
    padding: 6px 8px !important;
    transform: translateY(0) !important;
    filter: brightness(1) !important;
    transition: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1),
    filter 0.3s cubic-bezier(0.33, 1, 0.68, 1),
    box-shadow 0.6s cubic-bezier(0.33, 1, 0.68, 1) !important;
    will-change: transform, filter, box-shadow !important;
    cursor: pointer;
}

.menuButton:hover {
    transform: translateY(-2px) !important;
    filter: brightness(1.05) !important;
    box-shadow:
    inset -2px -2px 0 0 #284755,
    inset 2px 2px 0 0 #abbdc6,
    inset -4px -4px 0 0 #315667,
    inset 4px 4px 0 0 #99b0baad, 
    0 8px 16px rgba(0, 0, 0, 0.15),
    0 4px 8px rgba(0, 0, 0, 0.12) !important;
}

.menuButton:active {
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1) !important;
    transform: translateY(2px) !important;
    transform: translateX(2px) !important;
    filter: brightness(0.98) !important;
    font-size: 0.96rem;
    background: #42748a !important;
    box-shadow:
        inset -2px -2px 0 0 #284755,
        inset 2px 2px 0 0 #abbdc6,
        inset -4px -4px 0 0 #31566775,
        inset 4px 4px 0 0 #99b0ba47 !important;
    padding: 8px 6px 4px 10px !important;
}

/* Mobile Adjustments */
@media screen and (max-width: 768px) {
    .overlay-button, 
    #stakeButton, 
    #unstakeButton, 
    #purchaseButton, 
    #redeemButton {
        width: 160px !important;
        height: 40px !important;
        font-size: 13px !important;
        margin: 0px 3px !important;
        box-shadow:
        inset -1.2px -1.2px 0 0 #000000,
        inset 1.4px 1.4px 0 0 #ffffff,
        inset -3px -3px 0 0 #808080,
        inset 3px 3px 0 0 #dfdfdf30 !important;
    }

    .overlay-button:active, 
    #stakeButton:active, 
    #unstakeButton:active, 
    #purchaseButton:active, 
    #redeemButton:active {
        box-shadow:
        inset -1.4px -1.4px 0 0 #ffffff,
        inset 1.2px 1.2px 0 0 #000000,
        inset -3px -3px 0 0 #dfdfdf30,
        inset 3px 3px 0 0 #707070d8 !important;
    }
    
    
    #maxStakeButton {
        width: 70px !important;
        height: 44px !important;
        font-size: 13px !important;
        margin-top: 15px !important;
        margin-left: 0px !important;
        box-shadow:
        inset -1.2px -1.2px 0 0 #000000,
        inset 1.4px 1.4px 0 0 #ffffff,
        inset -3px -3px 0 0 #808080,
        inset 3px 3px 0 0 #dfdfdf30 !important;
    }

    #maxStakeButton:active {
        box-shadow:
        inset -1.4px -1.4px 0 0 #ffffff,
        inset 1.2px 1.2px 0 0 #000000,
        inset -3px -3px 0 0 #dfdfdf30,
        inset 3px 3px 0 0 #707070d8 !important;
    }

    .menuButton {
        width: 145px !important;
        height: 40px !important;
        margin-bottom: 0px !important;
    }
}

/* Card Animation Keyframes */
@keyframes cardEnter {
    0% {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.98);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes cardExit {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -52%) scale(0.98);
    }
}

/* Card Base Styles */
.card {
    backface-visibility: hidden;
    will-change: transform, opacity;
}

/* Card Animation States */
.card-entering {
    animation: cardEnter 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.card-exiting {
    animation: cardExit 0.3s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Menu Animation Keyframes */
@keyframes menuItemEnter {
    0% {
        opacity: 0;
        transform: translateY(8px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Add smooth transition for transform */
.menuButton {
    transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Subtle hover effect for menu buttons */
.menuButton:hover {
    transform: translateY(-1px);
}

/* Safe Area Insets */
@supports (padding-top: env(safe-area-inset-top)) {
    #topBar {
        padding-top: env(safe-area-inset-top);
    }

    .card {
        max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 100px);
    }
}
/* Safe Area Insets for Footer */
@supports (padding-bottom: env(safe-area-inset-bottom)) {
    #footer {
        padding-bottom: calc(10px + env(safe-area-inset-bottom));
    }
    #menu {
        bottom: calc(var(--footer-height) + env(safe-area-inset-bottom) + 20px);
    }
}

/* Touch Action */
* {
    touch-action: pan-y;
}
